<script>
import { get } from '@shell/utils/object';

export default {
  props: {
    value: {
      type:    String,
      default: ''
    },
    row: {
      type:     Object,
      required: true
    },
    col: {
      type:    Object,
      default: () => {}
    },
    reference: {
      type:    String,
      default: null,
    },
    getCustomDetailLink: {
      type:    Function,
      default: null
    }
  },
  data(){
    return {
      dataMap:{
        'data-elasticsearch':'全文检索数据',
        'data-milvus':'向量数据',
        'data-minio':'文件数据',
        'data-mysql':'关系型数据',
        'data-tdengine':'时序型数据',
        'Manage Cluster Backups':'管理集群备份',
        'Kubernetes cluster-admin':'Kubernetes 集群管理',
        'Cluster Member':'集群成员',
        'Cluster Owner':'集群拥有者',
        'Manage Cluster Catalogs':'管理集群种类',
        'View Cluster Catalogs':'查看集群种类',
        'Manage Cluster Members':'管理集群成员',
        'View Cluster Members':'查看集群成员',
        'Manage Navlinks':'管理导航链接',
        'Manage Nodes':'管理节点',
        'View Nodes':'查看节点',
        'Manage Storage':'管理存储',
        'View All Projects':'查看所有项目',
        'Create Projects':'创建项目',
        'Kubernetes admin':'Kubernetes 管理员',
        'Manage Config Maps':'管理 Config Maps',
        'View Config Maps':'查看 Config Maps',
        'Create Namespaces':'创建命名空间',
        'Kubernetes edit':'Kubernetes 编辑',
        'Manage Ingress':'管理 Ingress',
        'View Ingress':'查看 Ingress',
        'View Monitoring':'查看 Monitoring',
        'View Navlinks':'查看导航链接',
        'Manage Volumes':'管理 Volumes',
        'View Volumes':'查看 Volumes',
        'Project Member':'项目成员',
        'Project Monitoring View Role':'项目监控查看角色',
        'Project Owner':'项目拥有者',
        'Manage Project Catalogs':'管理项目种类',
        'View Project Catalogs':'查看项目种类',
        'Manage Project Members':'管理项目成员',
        'View Project Members':'查看项目成员',
        'Read-only':'只读',
        'Manage Secrets':'管理 Secrets',
        'View Secrets':'查看 Secrets',
        'Manage Service Accounts':'管理服务账号',
        'View Service Accounts':'查看服务账号',
        'Manage Services':'管理服务',
        'View Services':'查看服务',
        'Kubernetes view':'Kubernetes 查看',
        'Manage Workloads':'管理工作负载',
        'View Workloads':'查看工作负载',
      },
    }
  },
  computed: {
    to() {
      if (this.getCustomDetailLink) {
        return this.getCustomDetailLink(this.row);
      }
      if ( this.row && this.reference ) {
        return get(this.row, this.reference);
      }

      return this.row?.detailLocation;
    }


    
  }
};
</script>

<template>
  <span>
    <n-link
      v-if="to"
      :to="to"
    >
      {{[ 'Manage Project Members','View Project Catalogs', 'View Volumes','View Project Members','View Workloads','Manage Workloads', 'Kubernetes view','View Services','Manage Services',  'Manage Service Accounts', 'View Service Accounts','View Secrets','Manage Secrets', 'Read-only','Manage Project Catalogs','Project Owner','Project Member','Project Monitoring View Role','Manage Volumes','View Navlinks','View Ingress','View Monitoring','Manage Ingress','Kubernetes edit','Create Namespaces','View Config Maps','Manage Config Maps','Kubernetes admin','Manage Storage','View All Projects','Create Projects','View Nodes','Manage Nodes','Manage Navlinks','View Cluster Members','Manage Cluster Members','Manage Cluster Catalogs','View Cluster Catalogs','Cluster Owner','Cluster Member','Kubernetes cluster-admin','Manage Cluster Backups','data-elasticsearch','data-milvus','data-minio','data-mysql','data-tdengine'].includes(value) ? dataMap[value]:value }}
    </n-link>
    <span v-else>
      {{ value }}
      <template v-if="!value && col.dashIfEmpty">
        <span class="text-muted">&mdash;</span>
      </template>
    </span>
  </span>
</template>
