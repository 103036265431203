var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vp-pagination"},[_c('div',{class:['vp-pagination_button', !_vm.prevText ? 'vp-pagination_pre' : '',_vm.currentPage === 1 ?'disabled':''],on:{"click":_vm.handleClickPre}},[(!_vm.prevText)?[_c('i',{staticClass:"icon icon-chevron-left"})]:[_c('span',[_vm._v(_vm._s(_vm.prevText))])]],2),_vm._v(" "),(_vm.lastPageNum <= 7)?_vm._l((_vm.lastPageNum),function(page){return _c('div',{key:page,class:[
        'vp-pagination_button',
        page === _vm.currentPage ? 'vp-pagination_button_active' : '',
      ],on:{"click":function($event){return _vm.handleClickPageNum(page)}}},[_vm._v("\n      "+_vm._s(page)+"\n    ")])}):[[_c('div',{class:[
          'vp-pagination_button',
          1 === _vm.currentPage ? 'vp-pagination_button_active' : '',
        ],on:{"click":function($event){return _vm.handleClickPageNum(1)}}},[_vm._v("\n        1\n      ")]),_vm._v(" "),(_vm.hasLeft)?_c('div',{staticClass:"vp-pagination_button",on:{"click":_vm.handleClickPreBtn}},[_vm._v("\n        ...\n      ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.middlePageNumArr),function(page){return _c('div',{key:page,class:[
          'vp-pagination_button',
          page === _vm.currentPage ? 'vp-pagination_button_active' : '',
        ],on:{"click":function($event){return _vm.handleClickPageNum(page)}}},[_vm._v("\n        "+_vm._s(page)+"\n      ")])}),_vm._v(" "),(_vm.hasRight)?_c('div',{staticClass:"vp-pagination_button",on:{"click":_vm.handleClickNextBtn}},[_vm._v("\n        ...\n      ")]):_vm._e(),_vm._v(" "),_c('div',{class:[
          'vp-pagination_button',
          _vm.lastPageNum === _vm.currentPage ? 'vp-pagination_button_active' : '',
        ],on:{"click":function($event){return _vm.handleClickPageNum(_vm.lastPageNum)}}},[_vm._v("\n        "+_vm._s(_vm.lastPageNum)+"\n      ")])]],_vm._v(" "),_c('div',{class:['vp-pagination_button', !_vm.nextText ? 'vp-pagination_next' : '',_vm.currentPage === _vm.lastPageNum ?'disabled':''],on:{"click":_vm.handleClickNext}},[(!_vm.nextText)?[_c('i',{staticClass:"icon icon-chevron-right"})]:[_c('span',[_vm._v(_vm._s(_vm.nextText))])]],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }