<script>
import { _EDIT, _VIEW } from '@shell/config/query-params';
import { set } from '@shell/utils/object';

export function createOnSelected(field) {
  return function (contents) {
    set(this, field, contents);
  };
}

export default {
  props: {
    label: {
      type: String,
      required: true
    },

    mode: {
      type: String,
      default: _EDIT
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    includeFileName: {
      type: Boolean,
      default: false,
    },

    showGrowlError: {
      type: Boolean,
      default: true
    },

    multiple: {
      type: Boolean,
      default: false
    },

    byteLimit: {
      type: Number,
      default: 0
    },

    readAsDataUrl: {
      type: Boolean,
      default: false
    },

    directory: {
      type: Boolean,
      default: false
    },

    rawData: {
      type: Boolean,
      default: false
    },

    accept: {
      type: String,
      default: '*'
    },

    closeIcon:{
      type: Boolean,
      default: false
    },

  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    isView() {
      return this.mode === _VIEW;
    },
    bytesToGB() {
      return this.byteLimit / 1024 / 1024 / 1024;
    }
  },
  methods: {
    selectFile() {
      // Clear the value so the user can reselect the same file again
      this.$refs.uploader.value = null;
      this.$refs.uploader.click();
    },

    async fileChange(event) {
      this.loading = true
      const input = event.target;
      const files = Array.from(input.files || []);
      if (this.byteLimit) {
        for (const file of files) {
          if (file.size > this.byteLimit) {
            this.$emit('error', `${file.name} exceeds the file size limit of ${this.byteLimit} bytes`);
            this.$store.dispatch('growl/warning', { title: '提示', message: `文件 ${file.name} 超过了文件大小的限制，该限制为 ${this.bytesToGB} GB` }, { root: true });
            this.loading = false
            return;
          }
        }
      }

      if (this.rawData) {
        const unboxedContents = !this.multiple && files.length === 1 ? files[0] : files;
        this.loading = false
        this.$emit('selected', unboxedContents);

        return;
      }

      try {
        const asyncFileContents = files.map(this.getFileContents);
        const fileContents = await Promise.all(asyncFileContents);
        const unboxedContents = !this.multiple && fileContents.length === 1 ? fileContents[0] : fileContents;
        this.loading = false
        this.$emit('selected', unboxedContents);
      } catch (error) {
        this.loading = false
        this.$emit('error', error);
        // 展示错误消息提示
        if (this.showGrowlError) {
          this.loading = false
          this.$store.dispatch('growl/fromError', { title: '读取文件错误', error }, { root: true });
        }
      }
    },

    getFileContents(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (ev) => {
          const value = ev.target.result;
          const name = file.name;
          const fileContents = this.includeFileName ? { value, name, file } : value
          resolve(fileContents);
        };

        reader.onerror = (err) => {
          reject(err);
        };
        if (this.readAsDataUrl) {
          reader.readAsDataURL(file);
        } else {
          reader.readAsText(file);
        }
      });
    }
  }
};
</script>

<template>
  <button v-if="!isView" :disabled="disabled" type="button" class="file-selector btn" @click="selectFile">
    <slot name="icon" v-if="!loading"></slot>
    <span v-else>文件加载中&nbsp;&nbsp;<i class="icon icon-spinner icon-spin"></i></span>
    <span @click.stop v-if="label" class="info">{{ label }}
      <i class="icon icon-close" v-if='closeIcon' @click.stop="$emit('update:label', '')" />
    </span>
    <input ref="uploader" type="file" class="hide" :multiple="multiple" :webkitdirectory="directory" :accept="accept"
      @change="fileChange">
  </button>
</template>

<style lang="scss" scoped> .info {
   display: flex;
   align-items: center;
   gap: 10px;
 }
</style>
