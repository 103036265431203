// system routes
export const SYSTEMMANAGEMENTAPPS = [
  {
    label: '用户管理',
    icon: 'icon-user',
    value: 'auth',
    removable: false,
    inStore: 'management',
    weight: 102,
    category: 'none',
    route: '/c/local/auth/management.cattle.io.user'
  },
  {
    label: '角色管理',
    icon: 'icon-user',
    value: 'auth-role',
    removable: false,
    inStore: 'management',
    weight: 102,
    category: 'none',
    route: '/c/local/auth/roles#GLOBAL'
  },
  {
    label: '集群管理',
    icon: 'icon-cluster-management',
    value: 'manager',
    removable: false,
    inStore: 'management',
    weight: 1,
    category: 'none',
    route: '/c/local/explorer/node'
  },
  {
    label: '存储管理',
    icon: 'icon-cluster-management',
    value: 'manager',
    removable: false,
    inStore: 'management',
    weight: 1,
    category: 'none',
    route: '/c/local/explorer/persistentvolumeclaim'
  },
  {
    label: '日志管理',
    icon: 'icon-cluster-management',
    value: 'manager',
    removable: false,
    inStore: 'management',
    weight: 1,
    category: 'none',
    route: '/c/local/explorer/event'
  }
];

// task routes
export const TASKMANAGEMENTAPPS = [
  {
    label: '训练任务',
    icon: 'icon-harvester',
    value: 'auth',
    removable: false,
    inStore: 'management',
    weight: 102,
    category: 'none',
    route: '/c/local/explorer/batch.job?type=train'
  },
  {
    label: '数据任务',
    icon: 'icon-extension',
    value: 'auth-role',
    removable: false,
    inStore: 'management',
    weight: 102,
    category: 'none',
    route: '/c/local/explorer/batch.job?type=data'
  },
  {
    label: '定时任务',
    icon: 'icon-globe',
    value: 'manager',
    removable: false,
    inStore: 'management',
    weight: 1,
    category: 'none',
    route: '/c/local/explorer/batch.cronjob'
  }
]

// model routes
export const MODELMANAGEMENTAPPS = [        
  {
       label: "镜像管理",
       icon: "icon-fleet",
       value: "auth",
       removable: false,
       inStore: "management",
       weight: 102,
       category: "none",
       route: '/c/local/explorer/images'
   },
   {
       label: "算法管理",
       icon: "icon-fleet",
       value: "auth",
       removable: false,
       inStore: "management",
       weight: 102,
       category: "none",
       route: '/c/local/explorer/algos'
   },
]

// service routes
export const SERVICEMANAGEMENTAPPS = [
  {
    label: '服务发布管理',
    icon: 'icon-extension',
    value: 'auth',
    removable: false,
    inStore: 'management',
    weight: 102,
    category: 'none',
    route: '/c/local/explorer/servicepublish'
  },
  {
    label: '服务注册发现',
    icon: 'icon-extension',
    value: 'auth',
    removable: false,
    inStore: 'management',
    weight: 102,
    category: 'none',
    route: '/c/local/explorer/service'
  },
  {
    label: '负载均衡管理',
    icon: 'icon-extension',
    value: 'auth-role',
    removable: false,
    inStore: 'management',
    weight: 102,
    category: 'none',
    route: '/c/local/explorer/apps.deployment?type=loadbalance'
  },
  {
    label: '服务配置管理',
    icon: 'icon-extension',
    value: 'manager',
    removable: false,
    inStore: 'management',
    weight: 1,
    category: 'none',
    route: '/c/local/explorer/configmap'
  },
  {
    label: '对外服务管理',
    icon: 'icon-extension',
    value: 'manager',
    removable: false,
    inStore: 'management',
    weight: 1,
    category: 'none',
    route: '/c/local/explorer/networking.k8s.io.ingress'
  }
]

// train routes
export const TRAINMANAGEMENTAPPS = [
  {
    label: "数据集",
    icon: "icon-fleet",
    value: "auth",
    removable: false,
    inStore: "management",
    weight: 102,
    category: "none",
    route: '/c/local/explorer/dataset'
  },
  {
    label: "训练数据",
    icon: "icon-fleet",
    value: "auth",
    removable: false,
    inStore: "management",
    weight: 102,
    category: "none",
    route: '/c/local/explorer/traindata'
  },
  {
    label: "训练任务",
    icon: "icon-fleet",
    value: "auth",
    removable: false,
    inStore: "management",
    weight: 102,
    category: "none",
    route: '/c/local/explorer/traintask'
  },
  {
    label: "模型文件",
    icon: "icon-fleet",
    value: "auth",
    removable: false,
    inStore: "management",
    weight: 102,
    category: "none",
    route: '/c/local/explorer/model'
  }
]

// data routes
export const DATAMANAGEMENTAPPS = [
  {
    label: '数据管理列表',
    icon: 'icon-fleet',
    value: 'auth',
    removable: false,
    inStore: 'management',
    weight: 202,
    category: 'none',
    route: '/c/local/explorer/apps.deployment?type=datamanage'
  }
]

// home route
export const HOMEAPP = [
  {
    label: 'AI门户',
    icon: 'icon-fleet',
    value: 'auth',
    removable: false,
    inStore: 'management',
    weight: 102,
    category: 'none',
    route: '/c/local/explorer#cluster-events'
 }
]
