export const DATA_STATUS_MAP = {
    new: '新导入',
    waiting: '等待导入',
    importing: '导入中',
    imported: '导入完成',
    error: '导入错误'
};

export const TASK_STATUS_MAP = {
    new: '新的任务',
    waiting: '等待训练',
    importing: '训练中',
    imported: '训练完成',
    error: '训练错误'
};


export const DATA_TYPE_MAP = {
    0: '数据库',
    1: '文件',
    2: '文件URL',
};

export const STATUS_CLASS_MAP = {
    success: {
        color: 'bg-success',
        icon: 'icon-checkmark'
    },
    warning: {
        color: 'bg-warning',
        icon: 'icon-warning'
    },
    info: {
        color: 'bg-info',
        icon: 'icon-info'
    },
    error: {
        color: 'bg-error',
        icon: 'icon-error'
    },
    running: {
        color: 'bg-running',
        icon: 'icon-running'
    }
};

export const SUANFA_MAP = {
    'suanfa-embedding': '词嵌入算法',
    'suanfa-keywords': '智能凝练和标签提取',
    'suanfa-mingan': '敏感词检测算法',
    'suanfa-recommend': '智能推荐算法',
    'suanfa-techmatch': '标签分类算法',
    'suanfa-techcheck': '卡脖子技术判断',
    'suanfa-text2vect': '文本向量化算法',
    'suanfa-value': '智能估值算法',
    'suanfa-smartsessions': '智能会话',
    'suanfa-similaritymatch': '相似度匹配',
    'suanfa-policy-search': '政策搜索算法',
    'suanfa-compliance-check': '合规性检测算法',
    'suanfa-14bmodelggml': '自然语言大模型'
}

export const REASON_MAP = {
    'Detached': '已分离',
    'Attached': '已附加',
    'Pulling': '正在拉取',
    'Pulled': '已拉取',
    'FailedMount': '挂载失败',
    'Completed': '已完成',
    'SawCompletedJob': '已完成任务',
    'Killing': '正在结束',
    'Start': '开始',
    'Started': '已开始',
    'Stop': '停止',
    'Created': '已创建',
    'Unhealthy': '异常',
    'SuccessfulAttachVolume': '卷挂载成功',
    'SuccessfulCreate': '创建成功',
    'SuccessfulDelete': '成功删除',
    'FailedScheduling': '调度失败',
    'Scheduled': '已调度',
    'ScalingReplicaSet': '正在伸缩副本',
    'ErrImageNeverPull': '镜像拉取失败',
    'Failed': '失败',
    'BackOff': '中断',
    'Sync': '同步'
}

export const EVENTTYPE_MAP = {
    'Warning': '警告',
    'Normal': '正常',
}