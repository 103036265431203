<template>
    <div class="vp-pagination">
      <!-- 前 -->
      <div
        :class="['vp-pagination_button', !prevText ? 'vp-pagination_pre' : '',currentPage === 1 ?'disabled':'']"
        @click="handleClickPre"
      >
        <template v-if="!prevText">
          <i class="icon icon-chevron-left" />
        </template>
        <template v-else>
          <span>{{ prevText }}</span>
        </template>
      </div>
  
      <!-- 内容 -->
      <template v-if="lastPageNum <= 7">
        <div
          :class="[
            'vp-pagination_button',
            page === currentPage ? 'vp-pagination_button_active' : '',
          ]"
          v-for="page in lastPageNum"
          :key="page"
          @click="handleClickPageNum(page)"
        >
          {{ page }}
        </div>
      </template>
      <template v-else>
        <template>
          <!-- 第一个 -->
          <div
            :class="[
              'vp-pagination_button',
              1 === currentPage ? 'vp-pagination_button_active' : '',
            ]"
            @click="handleClickPageNum(1)"
          >
            1
          </div>
          <div
            v-if="hasLeft"
            class="vp-pagination_button"
            @click="handleClickPreBtn"
          >
            ...
          </div>
          <!-- 中间 -->
          <div
            :class="[
              'vp-pagination_button',
              page === currentPage ? 'vp-pagination_button_active' : '',
            ]"
            v-for="page in middlePageNumArr"
            :key="page"
            @click="handleClickPageNum(page)"
          >
            {{ page }}
          </div>
          <div
            v-if="hasRight"
            class="vp-pagination_button"
            @click="handleClickNextBtn"
          >
            ...
          </div>
          <!-- 最后一个 -->
          <div
            :class="[
              'vp-pagination_button',
              lastPageNum === currentPage ? 'vp-pagination_button_active' : '',
            ]"
            @click="handleClickPageNum(lastPageNum)"
          >
            {{ lastPageNum }}
          </div>
        </template>
      </template>
  
      <!-- 后 -->
      <div
        :class="['vp-pagination_button', !nextText ? 'vp-pagination_next' : '',currentPage === lastPageNum ?'disabled':'']"
        @click="handleClickNext"
      >
        <template v-if="!nextText">
          <i class="icon icon-chevron-right" />
        </template>
        <template v-else>
          <span>{{ nextText }}</span>
        </template>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CustomPagination",
    props: {
      // 当前页码
      currentPage: {
        type: Number,
        default: 1,
      },
      // 每页条大小
      pageSize: {
        type: Number,
        default: 10,
      },
      // 总数
      total: {
        type: Number,
        default: 10,
      },
      // 单页是否被隐藏
      hideOnSinglePage: {
        type: Boolean,
        default: false,
      },
      // 布局
      layout: {
        type: String,
        default: "", // jumper
      },
      // 上一页文字
      prevText: {
        type: String,
        default: "",
      },
      // 下一页文字
      nextText: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        hasLeft: false,
        hasRight: false,
        page: this.currentPage,
        filterPage: 1,
      };
    },
    watch: {
      currentPage(newVal) {
        this.$emit("current-change", newVal);
        this.page = newVal;
      },
    },
    computed: {
      // 最后一页的页码数
      lastPageNum() {
        return Math.ceil(this.total / this.pageSize);
      },
      // 中间的5个页码数组
      middlePageNumArr() {
        let result = [];
        switch (this.currentPage) {
          case 1:
            result = [2, 3, 4, 5, 6];
            break;
          case this.lastPageNum:
            result = [
              this.lastPageNum - 5,
              this.lastPageNum - 4,
              this.lastPageNum - 3,
              this.lastPageNum - 2,
              this.lastPageNum - 1,
            ];
            break;
          default:
            if (this.currentPage - 3 > 1) {
              this.hasLeft = true;
              result = [
                this.currentPage - 2,
                this.currentPage - 1,
                this.currentPage,
                this.currentPage + 1,
                this.currentPage + 2,
              ];
            } else {
              this.hasLeft = false;
              result = [2, 3, 4, 5, 6];
            }
            if (this.currentPage + 3 < this.lastPageNum) {
              this.hasRight = true;
              result = result || [
                this.currentPage - 2,
                this.currentPage - 1,
                this.currentPage,
                this.currentPage + 1,
                this.currentPage + 2,
              ];
            } else {
              this.hasRight = false;
              result = [
                this.lastPageNum - 5,
                this.lastPageNum - 4,
                this.lastPageNum - 3,
                this.lastPageNum - 2,
                this.lastPageNum - 1,
              ];
            }
            break;
        }
        return result;
      },
    },
    created() {
      this.handleInit();
    },
    methods: {
      // 点击页码
      handleClickPageNum(pageNum) {
        this.$emit("update:currentPage", pageNum);
      },
      // 点击前一页
      handleClickPre() {
        if (this.currentPage !== 1) {
          this.$emit("update:currentPage", this.currentPage - 1);
        }
        let currentPage = this.currentPage === 1 && 1;
        currentPage = this.currentPage !== 1 && this.currentPage - 1;
        currentPage = currentPage || 1;
        this.$emit("pre-click", currentPage);
      },
  
      // 点击下一页
      handleClickNext() {
        if (this.currentPage !== this.lastPageNum) {
          this.$emit("update:currentPage", this.currentPage + 1);
        }
        let currentPage =
          this.currentPage === this.lastPageNum && this.lastPageNum;
        currentPage =
          this.currentPage !== this.lastPageNum && this.currentPage + 1;
        currentPage = currentPage || this.lastPageNum;
        this.$emit("next-click", currentPage);
      },
  
      // 初始化是否显示 left right
      handleInit() {
        switch (this.currentPage) {
          case 1:
            if (this.lastPageNum > 7) {
              this.hasRight = true;
            }
            break;
          case this.lastPageNum:
            if (this.lastPageNum > 7) {
              this.hasLeft = true;
            }
            break;
          default:
            if (this.currentPage - 3 > 1) {
              this.hasLeft = true;
            } else {
              this.hasLeft = false;
            }
            if (this.currentPage + 3 < this.lastPageNum) {
              this.hasRight = true;
            } else {
              this.hasRight = false;
            }
            break;
        }
      },
  
      /**
       * 点击左边 ... 按钮
       */
      handleClickPreBtn() {
        if (this.currentPage !== 1) {
          this.$emit("update:currentPage", this.currentPage - 1);
        }
      },
  
      /**
       * 点击右边 ... 按钮
       */
      handleClickNextBtn() {
        if (this.currentPage !== this.lastPageNum) {
          this.$emit("update:currentPage", this.currentPage + 1);
        }
      },
  
      /**
       * 输入框 - input
       */
      handleInput(val) {
        let newVal = String(val);
        newVal = newVal.replace(/\s/g, "");
        newVal = newVal.replace(/[a-zA-Z]/g, "");
        newVal = newVal.replace(/^0/, "");
        newVal = Number(newVal);
        this.page = newVal;
        val = newVal;
      },
  
      /**
       * input blur 事件
       */
      handleInputBlur() {
        let page = this.lastPageNum >= this.page ? this.page : this.currentPage;
        this.$emit("update:currentPage", page);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .vp-pagination {
    user-select: none;
    .vp-pagination_pre, .vp-pagination_next {
      display: inline-block;
      background-color: transparent !important;
      .icon {
        font-size: 14px;
      }
    }
    .disabled {
      color:var(--lighterText)!important;
      cursor:not-allowed !important;
      &:hover{
        color:var(--lighterText) !important;
      }
    }
  
    .vp-pagination_button {
      min-width: 20px;
      min-height: 24px;
      text-align: center;
      line-height: 24px;
      color: var(--lighterText);
      padding: 8px 12px;;
      background-color: #FAFAFA;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 8px;
      font-weight: 700;
      display: inline-block;
  
      &:last-child {
        margin-right: 0;
      }
  
      &:hover {
        color: var(--primary);
      }
    }
  
    .vp-pagination_button_active {
      color: var(--primary-text);
      background-color: var(--primary);
      &:hover {
        color: var(--primary-text) !important;
      }
    }
  
  }
  </style>
  