<script>
export default {
  name: "TabbedLinks",

  props: {
    /**
     * Default tab to display from the list
     */
    defaultTab: {
      type: String,
      default: null,
      required: false,
    },
    /**
     * The list of tabs to display
     * @model
     */
    tabList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    const { tabList } = this;

    return { tabs: tabList };
  },
};
</script>

<template>
  <div>
    <ul
      v-if="tabs !== null && tabs.length > 0"
      role="tablist"
      class="tabs clearfix"
    >
      <n-link
        v-for="tab in tabs"
        :key="tab.name"
        :to="tab.route"
        tag="li"
        role="presentation"
        class="tab"
        exact-active-class="active"
        exact
      >
        <a>
          {{ tab.label }}
        </a>
      </n-link>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.tabs {
  list-style-type: none;
  margin: 8px auto;
  padding: 0;

  .tab {
    position: relative;
    float: left;
    margin-right: 32px;
    cursor: pointer;

    A {
      display: block;
      padding: 8px 0px;
      color: var(--body-text);
      text-decoration: none;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      A {
        color: var(--primary);
        font-weight: 600;
        text-decoration: none;
        border-bottom: 2px solid  var(--primary);
      }
    }
  }
}
</style>
