<script>
export default {
  name: 'CustomNav',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<template>
  <div class="collapse">
    <div class="collapsenav">
      <div class="advanced" data-testid="collapse-div">
        <slot name="titleicon"></slot>{{ title }}
      </div>
      <slot name="title">
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.advanced {
  user-select: none;
  padding: 0 12px;
  cursor: pointer;
  line-height: 38px;
  font-size: 16px;
  font-weight: 500;
  height: 38px;
  svg {
    margin-right: 4px;
  }
}
.advanced:hover{
  background: var(--primary-hover-bg);
  border-radius: 4px;
}


.collapse {
   width: auto;
  .collapsenav {
    display: flex;
    flex-direction: row;
  }
}

</style>
