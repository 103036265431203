var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"inputbox"},[_vm._t("label",function(){return [(_vm.hasLabel)?_c('label',[(_vm.labelKey)?_c('t',{attrs:{"k":_vm.labelKey}}):(_vm.label)?[_vm._v(_vm._s(_vm.label))]:_vm._e(),_vm._v(" "),(_vm.requiredField)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()],2):_vm._e()]}),_vm._v(" "),_c('div',{class:{
    'labeled-input': true,
    focused: _vm.focused,
    [_vm.mode]: true,
    disabled: _vm.isDisabled,
    [_vm.status]: _vm.status,
    suffix: _vm.hasSuffix,
    'has-tooltip': _vm.hasTooltip,
    'compact-input': _vm.isCompact,
    hideArrows: _vm.hideArrows
  }},[_vm._t("prefix"),_vm._v(" "),_vm._t("field",function(){return [(_vm.type === 'multiline' || _vm.type === 'multiline-password')?_c('TextAreaAutoGrow',_vm._b({ref:"value",class:{ conceal: _vm.type === 'multiline-password' },attrs:{"maxlength":_vm._maxlength,"disabled":_vm.isDisabled,"value":_vm.value,"placeholder":_vm._placeholder,"autocapitalize":"off"},on:{"input":function($event){return _vm.onInput($event)},"focus":_vm.onFocus,"blur":_vm.onBlur}},'TextAreaAutoGrow',_vm.$attrs,false)):_c('input',_vm._b({ref:"value",class:{ 'no-label': !_vm.hasLabel },attrs:{"maxlength":_vm._maxlength,"disabled":_vm.isDisabled,"type":_vm.type === 'cron' ? 'text' : _vm.type,"placeholder":_vm._placeholder,"autocomplete":"off","autocapitalize":"off","data-lpignore":_vm.ignorePasswordManagers},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.onInput($event.target.value)},"focus":_vm.onFocus,"blur":_vm.onBlur,"change":_vm.onChange}},'input',_vm.$attrs,false))]}),_vm._v(" "),_vm._t("suffix"),_vm._v(" "),(_vm.hasTooltip && !_vm.focused)?_c('LabeledTooltip',{attrs:{"hover":_vm.hoverTooltip,"value":_vm.tooltipValue,"status":_vm.status}}):_vm._e(),_vm._v(" "),(!!_vm.validationMessage)?_c('LabeledTooltip',{attrs:{"hover":_vm.hoverTooltip,"value":_vm.validationMessage}}):_vm._e(),_vm._v(" "),(_vm.cronHint)?_c('label',{staticClass:"cron-label"},[_vm._v(_vm._s(_vm.cronHint))]):_vm._e(),_vm._v(" "),(_vm.subLabel)?_c('label',{staticClass:"sub-label"},[_vm._v(_vm._s(_vm.subLabel))]):_vm._e()],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }